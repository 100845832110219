import React, { useState, useEffect } from 'react';
import { Bell, Calendar, FileText, Download, Mail, User, MessageSquare } from 'lucide-react';

const Logo = ({ isScrolled }) => (
  <div className={`transition-all duration-300 ${isScrolled ? 'text-2xl' : 'text-3xl'}`}>
    <div className={`font-bold uppercase tracking-wider ${isScrolled ? 'text-purple-700' : 'text-white'}`}>
      RX Reminder
    </div>
  </div>
);

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would typically send the form data to a server
    console.log('Form submitted:', formData);
    // Reset form after submission
    setFormData({ name: '', email: '', message: '' });
    alert('Thank you for your message. We will get back to you soon!');
  };

  return (
    <form onSubmit={handleSubmit} className="max-w-lg mx-auto">
      <div className="mb-4">
        <label htmlFor="name" className="block text-gray-700 text-sm font-bold mb-2">Name</label>
        <div className="flex items-center border rounded-md">
          <User className="mx-3 text-gray-400" size={20} />
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            className="w-full p-2 focus:outline-none"
            placeholder="Your Name"
          />
        </div>
      </div>
      <div className="mb-4">
        <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">Email</label>
        <div className="flex items-center border rounded-md">
          <Mail className="mx-3 text-gray-400" size={20} />
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            className="w-full p-2 focus:outline-none"
            placeholder="your@email.com"
          />
        </div>
      </div>
      <div className="mb-6">
        <label htmlFor="message" className="block text-gray-700 text-sm font-bold mb-2">Message</label>
        <div className="flex items-start border rounded-md">
          <MessageSquare className="mx-3 mt-3 text-gray-400" size={20} />
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
            className="w-full p-2 focus:outline-none"
            rows="4"
            placeholder="Your message here..."
          ></textarea>
        </div>
      </div>
      <div className="flex justify-end">
        <button type="submit" className="bg-purple-700 text-white py-2 px-4 rounded-md hover:bg-purple-800 transition-colors">
          Send Message
        </button>
      </div>
    </form>
  );
};

const App = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="font-sans text-gray-900">
      <header className={`fixed w-full transition-all duration-300 ${isScrolled ? 'bg-white shadow-lg' : 'bg-transparent'}`}>
        <div className="container mx-auto px-4 py-4 flex justify-between items-center">
          <Logo isScrolled={isScrolled} />
          <nav>
            <ul className="flex space-x-6">
              {['Features', 'About', 'Contact', 'Download'].map((item) => (
                <li key={item}>
                  <a href={`#${item.toLowerCase()}`} className={`hover:text-purple-500 transition-colors ${isScrolled ? 'text-gray-700' : 'text-white'}`}>{item}</a>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </header>

      <main>
        <section className="bg-gradient-to-r from-purple-700 to-indigo-800 text-white py-32">
          <div className="container mx-auto px-4 text-center">
            <h2 className="text-5xl font-bold mb-6">Take Control of Your Medication</h2>
            <p className="text-xl mb-8">Manage your medication schedule effortlessly with RX Reminder</p>
            <a href="#download" className="bg-white text-purple-700 py-3 px-8 rounded-full font-semibold hover:bg-gray-100 transition-colors">Get Started</a>
          </div>
        </section>

        <section id="features" className="py-20">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold text-center mb-12">Key Features</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {[
                { icon: Bell, title: 'Customizable Reminders', description: 'Set reminders for daily, weekly, or custom schedules.' },
                { icon: Calendar, title: 'Medication Tracking', description: 'Keep track of your medication history and adherence.' },
                { icon: FileText, title: 'Symptom Logging', description: 'Record symptoms or side effects to discuss with your doctor.' },
              ].map((feature, index) => (
                <div key={index} className="text-center p-6 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow">
                  <feature.icon className="mx-auto text-purple-600 mb-4" size={48} />
                  <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                  <p className="text-gray-600">{feature.description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section id="about" className="bg-gray-100 py-20">
          <div className="container mx-auto px-4 text-center">
            <h2 className="text-3xl font-bold mb-6">About RX Reminder</h2>
            <p className="text-xl max-w-2xl mx-auto">
              RX Reminder was developed to help people manage their medications more effectively,
              improving health outcomes and providing peace of mind.
            </p>
          </div>
        </section>

        <section id="contact" className="py-20">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold text-center mb-12">Contact Us</h2>
            <ContactForm />
          </div>
        </section>

        <section id="download" className="bg-gray-100 py-20">
          <div className="container mx-auto px-4 text-center">
            <h2 className="text-3xl font-bold mb-6">Download RX Reminder</h2>
            <p className="text-xl mb-8">Available now on iOS devices. Android coming soon!</p>
            <a href="#" className="inline-flex items-center bg-purple-700 text-white py-3 px-8 rounded-full font-semibold hover:bg-purple-800 transition-colors">
              <Download className="mr-2" size={24} />
              Download on the App Store
            </a>
          </div>
        </section>
      </main>

      <footer className="bg-gray-900 text-white py-8">
        <div className="container mx-auto px-4 text-center">
          <p>&copy; 2024 RX Reminder. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default App;
